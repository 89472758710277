<template>
  <div class="containar">
    <!-- 轮播 -->
    <Slide :slideshow="slideshow" />
    <div class="list" ref="list">
      <el-card
        v-for="(item, i) in sceneList"
        :key="i"
        :body-style="{ padding: '0px' }"
      >
        <div class="content">
          <div class="left">
            <h3>{{ item.siteName }}</h3>
            <div class="intro_box">
              <el-image
                style="width: 50%; height: 200px; border-radius: 5px"
                :src="item.sitePicture"
                fit="cover"
              ></el-image>
              <div class="intro">
                {{ item.siteIntroduction }}
              </div>
            </div>
            <div class="btn_box">
              <button
                class="btn"
                @click="$router.push('/scene/sceneInfo?siteId=' + item.id)"
              >
                查看详情
              </button>
              <button
                :class="item.isOpen == 2 ? 'btn' : 'btn disabled'"
                @click="handleAppointment(item)"
              >
                团队参观
              </button>
            </div>
          </div>
          <div class="right">
            <h4>课程推荐</h4>
            <div :style="item.carouselStyle" class="carousel">
              <div
                v-for="(val, j) in item.siteCourse"
                :key="j"
                class="carousel_item"
                :style="{
                  width: item.siteCourse.length == 1 ? '100%' : '270px',
                }"
                @click="
                  $router.push('/course/courseInfo?courseId=' + val.courseId)
                "
              >
                <el-image
                  style="width: 100%; height: 200px; border-radius: 5px"
                  :src="val.coursePicture"
                  fit="cover"
                ></el-image>
                <p class="carousel_item_text">{{ val.courseName }}</p>
              </div>
            </div>
            <empty-box v-if="item.siteCourse.length < 1">暂无课程</empty-box>
            <span
              @click="handleArrow(i, 290)"
              v-if="item.siteCourse.length > 2"
              class="arrow el-icon-arrow-left"
            ></span>
            <span
              @click="handleArrow(i, -290)"
              v-if="item.siteCourse.length > 2"
              class="arrow el-icon-arrow-right"
            ></span>
          </div>
        </div>
      </el-card>
      <el-pagination
        v-if="total > pageSize"
        @current-change="changePage"
        background
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Slide from "@/components/slide";
import { getSceneList_api } from "@/api/scene";
import { set } from "@/utils/local.js";
import emptyBox from "@/components/empty.vue";

export default {
  components: {
    Slide,
    emptyBox,
  },
  data() {
    return {
      sceneList: [], // 场景列表
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页条数
      total: 0, // 总条数
    };
  },
  computed: {
    slideshow() {
      return this.$store.state.siteSlideshow;
    },
  },
  methods: {
    // 获取场景列表
    async getSceneList() {
      let { data, code } = await getSceneList_api({
        tenantId: this.$tenantId,
        size: this.pageSize,
        sort: "site_heat",
        sortType: "desc",
        current: this.currentPage,
      });
      if (code !== 200) return null;
      console.log(data);
      data.records[0].siteCourse.splice(1, 1);
      // 动态设置课程处盒子的宽度
      data.records.forEach((item, i) => {
        item.carouselStyle = {
          width:
            item.siteCourse.length == 1
              ? "560px"
              : item.siteCourse.length * 290 - 20 + "px",
          left: 0,
          transition: "left .5s",
        };
      });
      this.total = data.total;
      this.sceneList = data.records;
    },
    // 点击场景里面课程的左右按钮
    handleArrow(i, num) {
      if (
        parseInt(this.sceneList[i].carouselStyle.left) <=
          560 - parseInt(this.sceneList[i].carouselStyle.width) &&
        num < 0
      )
        return this.$message("你已经在最前面了");
      if (parseInt(this.sceneList[i].carouselStyle.left) == 0 && num > 0)
        return this.$message("这里只能前进，不能倒退");
      this.sceneList[i].carouselStyle.left =
        parseInt(this.sceneList[i].carouselStyle.left) + num + "px";
    },
    // 切换页码
    changePage(currentPage) {
      this.currentPage = currentPage;
      document.documentElement.scrollTop = document.body.scrollTop =
        this.$refs.list.offsetTop - 150;
      this.getSceneList();
    },
    // 立即预约
    handleAppointment(item) {
      if (item.isOpen !== 2) return this.$message.error("当前场景不可预约");
      if (!this.$store.state.isLogin) {
        // 如果没登录
        this.$router.push("/login?redirect=/scene/sceneAdd?siteId=" + item.id);
      } else if (
        this.$store.state.userInfo.roleName === "学生" ||
        this.$store.state.userInfo.roleName === "student"
      ) {
        // 是学生
        this.$confirm(
          "检测到你的帐号为学生！团队只支持教师帐号预约！你可以去看看有哪些课程可以预约?",
          "提示",
          {
            confirmButtonText: "去看看课程",
            cancelButtonText: "留在当前页",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.push("/course");
          })
          .catch(() => {});
      } else {
        // 教师
        this.$router.push("/scene/sceneAdd?siteId=" + item.id);
      }
    },
  },
  created() {
    this.getSceneList();
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.list {
  margin: 100px auto;
   width: 60vw;
  .el-card {
    margin-bottom: 20px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    .left {
      width:calc(50% - 50px);
      margin-right: 50px;
      h3 {
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .intro_box {
        display: flex;
        margin-bottom: 20px;
        .intro {
          flex: 1;
          padding-left: 15px;
          font-size: 12px;
          line-height: 25px;
          letter-spacing: 2px;
          text-indent: calc(2em + 5px);
          color: #888;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
        }
      }
      .btn {
        width: 50%;
        height: 50px;
        font-size: 16px;
        background-color: @btn-color;
        &:first-child {
          background-color: @title-color;
        }
        &.disabled {
          background-color: #aaa;
          color: #fff;
        }
      }
    }
    .right {
      width: 50%;
      position: relative;
      overflow: hidden;
      h4 {
        font-weight: normal;
        text-align: center;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 20px;
      }
      .carousel {
        position: relative;
        .carousel_item {
          display: inline-block;
          margin-right: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          .carousel_item_text {
            font-weight: 600;
            font-size: 16px;
            margin-top: 20px;
            text-align: center;
          }
        }
      }
      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 20px;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
      }
      .el-icon-arrow-left {
        left: 10px;
      }
      .el-icon-arrow-right {
        right: 10px;
      }
    }
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: center;
  }
}
</style>